import { Flex } from './design-system'
import { Link } from 'gatsby'
import Logo from '../img/logo.inline.svg'
import { BiMenuAltRight } from 'react-icons/bi'
import React from 'react'
import styled from 'styled-components'
import theme from '../utils/theme'

const S = {}

S.Nav = styled.nav`
  margin-bottom: 2%;
  transition: 0.2s ease-in all;
  position: fixed;
  width: 100%;
  z-index: 9999;
  border-bottom: ${props => (props.isTop && !props.isMenuActive ? 'none' : '1px solid #ddd')};

  background: ${props => (props.isTop && !props.isMenuActive ? 'transparent' : 'white')};

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    margin-bottom: 0%;
  }
`

S.NavContainer = styled.nav`
  display: block;
  padding: 10px 10px 0 10px;
  align-items: center;
  justify-content: space-between;

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    display: flex;
    padding: 20px;
  }
`

S.MenuIcon = styled(BiMenuAltRight)`
  height: ${theme.fontSizes[2]};
  margin-left: auto;
  cursor: pointer;
  color: ${props => (props.isTop && !props.isMenuActive ? 'white' : 'black')};

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    display: none;
  }
`

S.NavLink = styled.a`
  position: relative;
  display: block;
  padding: 16px 16px;
  transition: 0.3s ease-in all;

  font-family: ${theme.fontPrimary};
  font-size: ${theme.fontSizes[5]};
  font-weight: 300;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1.5px;

  color: ${props => (props.isTop && !props.isMenuActive ? theme.color.white : theme.color.black)};
  &.active,
  &:hover {
    background: ${theme.color.brandBright};
    color: ${theme.color.black};
  }

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    padding: 8px 16px;

    & + & {
      margin-left: 4px;
    }
  }
`

S.Brand = styled(Flex)`
  padding: 1em 0;
  align-items: center;

  img {
    margin-bottom: 0;
  }

  a {
    margin: 0;
  }

  path,
  polygon {
    fill: ${props => (props.isTop && !props.isMenuActive ? 'white' : 'black')};
  }

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    padding: 0;
  }
`

S.NavItems = styled.div`
  display: none;
  position: relative;
  /* box-shadow: ${props => props.theme.boxShadows[0]}; */
  margin: 0 1em;

  &.active {
    display: block;
    background-color: white;
  }

  @media (min-width: ${props => props.theme.breakpoint.tablet}) {
    margin: 0;
    box-shadow: none;
    display: flex;
  }
`

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      listenToScroll: props.listenToScroll,
      isMenuActive: false,
      isTop: props.isTop,
    }

    this.toggleMenu = this.toggleMenu.bind(this)
  }

  componentDidMount() {
    if (this.state.listenToScroll) {
      document.addEventListener('scroll', () => {
        if (window.scrollY >= 100) {
          this.setState({ isTop: false })
        } else {
          this.setState({ isTop: true })
        }

        this.setState({ isMenuActive: false })
      })
    }
  }

  toggleMenu() {
    this.setState(state => ({
      isMenuActive: !state.isMenuActive,
    }))
  }

  render() {
    var navigations = [
      { title: 'Gallery', url: '/#gallery' },
      { title: 'About', url: '/#about' },
      { title: 'Social', url: '/#social' },
      { title: 'Menu', url: '/#menu' },
      { title: 'Store', url: 'https://whatsontapkl.company.site/' },
      { title: 'News', url: '/#news' },
      { title: 'Contact', url: '/#contact' },
    ]

    if (!this.props.showInstagram) {
      navigations = navigations.filter(nav => nav.title !== 'Social')
    }

    // if (!this.props.showStore) {
    //   navigations = navigations.filter(nav => nav.title !== 'Store')
    // }

    return (
      <S.Nav isMenuActive={this.state.isMenuActive} isTop={this.state.isTop}>
        <S.NavContainer>
          <S.Brand isTop={this.state.isTop} isMenuActive={this.state.isMenuActive}>
            <Link to="/">
              <Logo />
            </Link>
            <S.MenuIcon
              isMenuActive={this.state.isMenuActive}
              isTop={this.state.isTop}
              onClick={this.toggleMenu}
            />
          </S.Brand>
          <S.NavItems className={this.state.isMenuActive ? 'active' : ''}>
            {navigations.map(nav => (
              <S.NavLink
                key={nav.title}
                isMenuActive={this.state.isMenuActive}
                isTop={this.state.isTop}
                href={nav.url}
              >
                {nav.title}
              </S.NavLink>
            ))}
          </S.NavItems>
        </S.NavContainer>
      </S.Nav>
    )
  }
}

export default Navbar
